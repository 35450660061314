import React, { useState, useEffect, useContext } from "react"
import { navigate } from "gatsby"
import { useCookies } from 'react-cookie';

import axios from "axios"


/* Import Global Context(s) */
import TokenContext from "~context/token"
import AccountContext from "~context/account"

import ProfilePage from "~components/pages/profile/profilePage"
import AccountHeading from '~components/pages/profile/accountHeading'
import AccountView from '~components/pages/profile/accountView'


/* Import Local Style(s) */
import "./profile.scss"


const VIPProfile = ({ location }) => {
	

	const [token, setToken] = useContext(TokenContext)
	const [account, setAccount] = useContext(AccountContext)
	const [cookies, setCookie] = useCookies(['sessionToken']);

	// console.log(token)
	// console.log(account)
	// console.log(cookies)

	if(cookies.sessionToken && token === false){
		// if token is defined but the token context isn't set
		const { sessionToken } = cookies

		axios
    .post('/.netlify/functions/vipUsers', {
      contents: {token: sessionToken},
      method: 'with_auth',
      path: '/vips/auth/me'
    })
    .then(result => {

      // console.log(result)
      if(result.data.data !== undefined && result.data.data !== null){
        setAccount(result.data.data)
        setToken(sessionToken)

      }else{
        console.log('unauthorized / cookie token invalid')
      }

    })


	}else if(token === false){
		// if token is false
		if (typeof window !== `undefined`) {
      navigate("/vip")
    }
	}


	return (
		<ProfilePage title="VIP" header={"VIP"} location={location}>
			{ account && <AccountHeading location={location} profile={account} /> }
			{ account && <AccountView location={location} user={account} /> }
		</ProfilePage>
	)
}

export default VIPProfile