import React from "react"
import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"

import { useVipPage } from "~queries/hooks"

const AccountHeading = ({ profile, location }) => {

	const { displayVIPBenefits, displayVIPProgram } = useVipPage()

	return (<header className="font-size--small">		
		<p>Welcome back {profile.firstname} {profile.lastname}, please update your information below. Here you will also be able to access your VIP pass and RSVP for events once they become available.</p>

		<nav className="mt--2">
			{ displayVIPBenefits && profile.ticket && profile.ticket.links && <a href={profile.ticket.links.wallet} target="_blank" className="full_button blue_btn mb--1_2">Untitled Art, Miami Beach 2024 VIP Pass</a>	}
			{ displayVIPProgram && <Link className="full_button mb--1_2" to="/program">Untitled Art, Miami Beach 2024 VIP Program</Link>	}
			{/*{ displayVIPBenefits && profile.links && profile.links.events && <a href={profile.links.events} target="_blank" className="full_button blue_btn mb--1_2">Untitled Art, Miami Beach 2024 VIP Calendar</a>	}*/}
		</nav>
		

	</header>)

}

export default AccountHeading